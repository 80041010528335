<template>
  <!-- <div id="canvas"></div> -->
  <!-- <a-button type="link" size="small" @click="goEditTicket">去编辑</a-button> -->
  <iframe id="canvas" src="https://applet.arseek.cn/editor3d/CreateProject"></iframe>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
// import * as THREE from 'three'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
export default defineComponent({
  name: 'uploadManger',
  setup() {
    // let mixer = null
    // const scene = new THREE.Scene()
    // const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    // camera.position.set(0, 0, 10)
    // scene.add(camera)
    // // 导入模型
    // const loader = new GLTFLoader().setPath('./model/')
    // loader.load('./jing_a.glb', (object) => {
    //   // object.scene.scale.set(0.005, 0.005, 0.005)
    //   // object.scene.rotation.set(Math.PI / 6, -Math.PI / 6, 0)
    //   mixer = new THREE.AnimationMixer(object.scene)
    //   const action = mixer.clipAction(object.animations[0])
    //   action.play()
    //   scene.add(object.scene)
    // })

    // const ambientLight = new THREE.AmbientLight(0x404040, 40)
    // scene.add(ambientLight)
    // const renderer = new THREE.WebGLRenderer()
    // renderer.setSize(window.innerWidth, window.innerHeight)
    // // 创建轨道控制器
    // const orbitControls = new OrbitControls(camera, renderer.domElement)

    // // 自适应画面
    // window.addEventListener('resize', () => {
    //   // 更新摄像头
    //   camera.aspect = window.innerWidth / window.innerHeight
    //   // 更新摄像头的投影矩阵
    //   camera.updateProjectionMatrix()
    //   // 更新渲染器大小
    //   renderer.setSize(window.innerWidth, window.innerHeight)
    //   // 设置渲染器的像素比
    //   renderer.setPixelRatio(window.devicePixelRatio)
    // })
    // const clock = new THREE.Clock()

    // function render() {
    //   const delta = clock.getDelta()

    //   if (mixer) mixer.update(delta)
    //   renderer.render(scene, camera)
    //   requestAnimationFrame(render)
    // }

    nextTick(() => {
      // document.getElementById('canvas').appendChild(renderer.domElement)
      // render()
    })
    const goEditTicket = () => {
      window.open('http://applet.arseek.cn/editor3d/editor.html?id=48')
    }
    return {
      goEditTicket
    }
  }
})
</script>

<style lang="scss" scoped>
#canvas {
  width: calc(100% + 255px);
  height: calc(100% + 150px);
  margin-left: -255px;
  margin-top: -150px;
}
</style>
